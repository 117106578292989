@import '../../Styles/theme';
@import '../../Styles/text';
@import '../../Styles/forms';
@import '../../Styles/buttons';
@import '../../Styles/shapes';
@import '../../Styles/screens';
@import '../../Styles/scrollbar';

.personal-info__header {
  grid-area: header;
}

.personal-info__content {
  background-color: theme-color('white');
  grid-area: content;

  @media (min-width: $screen-lg) {
    margin: 2px 160px 0;
  }
}

.personal-info__title {
  @extend .screen-reader-only;
}

.personal-info__subtitle {
  background-color: #050520;
  border-top: 1px solid #050520;
  color: theme-color('white');
  font-size: 1.2rem;
  font-weight: 600;
  margin: -1px 0 0;
  padding: 5px 0;
  text-align: center;
  width: 100%;
}

.personal-info__info {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
  padding: 1.5em 1em 0;
  text-align: center;

  @media (min-width: $screen-lg) {
    font-size: 1.5rem;
    border-bottom: none;
    padding: 1.5em 0 0;
  }
}

.personal-info__subinfo {
  border-bottom: 1px solid theme-color('background', 'medium');
  font-size: 1rem;
  margin: 0;
  padding: 1.5em;
  text-align: center;

  @media (min-width: $screen-lg) {
    font-size: 1.2rem;
    border-bottom: none;
    padding: 0.75em 0 1.5em;
  }
}

.personal-info__form {
  @extend .custom-scrollbar;

  margin: 0 auto;
  padding-top: 2em;

  @media (min-width: $screen-lg) {
    width: 43em;
  }

  @media (min-width: $screen-lg) and (max-height: 1080px) {
    height: 48vh;
  }
}

.personal-info__row {
  display: flex;
  flex-direction: column;
  padding: 0 1em;

  @media (min-width: $screen-lg) {
    flex-direction: row;
  }
}

.personal-info__row--row {
  display: block;
}

.personal-info__input-cont {
  margin-bottom: 1em;
  position: relative;
  width: 100%;

  @media (min-width: $screen-lg) {
    &:first-child {
      margin-right: 1em;
    }

    &:last-child {
      margin-left: 1em;
    }
  }
}

.personal-info__input {
  width: 100%;
}

.personal-info__input-icon {
  @extend .shape-circle;
  background: none;
  background-color: theme-color('gray');
  border: none;
  color: theme-color('white');
  cursor: pointer;
  font-size: 1.2rem;
  height: 30px;
  outline: none;
  position: absolute;
  right: 1em;
  top: 0.7em;
  width: 30px;
}

.personal-info__tooltip {
  pointer-events: 'auto';
}

.personal-info__input--phone {
  .MuiInputBase-input {
    padding-right: 4em;
  }
}

.personal-info__error {
  color: theme-color('red');
  display: block;
  font-size: 0.8rem;
  margin-bottom: 1em;
  text-align: left;
  visibility: hidden;
}

.personal-info__error--visible {
  visibility: visible;
}

.personal-info__privacy {
  margin: 0;
  padding: 0 1em 1em;
  text-align: left;

  > a {
    color: theme-color('primary');
    text-decoration: none;
  }

  @media (min-width: $screen-lg) {
    text-align: center;
  }
}

.personal-info__inquiry {
  text-align: left;
  color: #1f1f1f;
  margin-left: 2em;

  > a {
    color: theme-color('primary');
    text-decoration: none;
  }
}

.personal-info__see-details-cont {
  background-color: theme-color('primary2');
  padding: 1em;

  @media (min-width: $screen-lg) {
    background-color: initial;
    padding: 0;
  }
}

.personal-info__see-details {
  @extend .button;
  @extend .button--primary;

  padding: 1em;
  text-transform: uppercase;
  width: 100%;

  @media (min-width: $screen-lg) {
    display: block;
    margin: 0 auto;
    width: 18em;
  }
}

.personal-info__see-details-warning {
  text-align: center;
  color: theme-color('red');
}

.personal-info__sms-label {
  font-size: 0.9rem;
  text-transform: uppercase;

  @media (min-width: $screen-lg) {
    font-size: 1rem;
  }
}

.personal-info__sms-one-way-warning{
  margin-top: -1em;
}

.grating > a {
  font-size: 1em;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  text-decoration: none;
}
