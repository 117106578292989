@import '../../Styles/theme';
@import '../../Styles/buttons';
@import '../../Styles/screens';
@import '../../Styles/shapes';

.footer {
  align-items: center;
  background-color: theme-color('primary2');
  display: flex;
  height: 100%;
  justify-content: center;

  &--mobile-hidden {
    display: none;

    @media (min-width: $screen-lg) {
      display: flex;
    }
  }

  @media (min-width: $screen-lg) {
    background: none;
    border-top: none;
    justify-content: space-between;
    padding: 0 2em 0;
    position: relative;
  }
}

.footer__sponsor {
  color: theme-color('white');
  margin: 0;

  @media (min-width: $screen-lg) {
    color: theme-color('white');
    bottom: 1em;
    font-size: 0.8rem;
    left: 2.5em;
    position: absolute;
  }
}

.footer__statement {
  color: theme-color('white');
  display: none;
  margin: 0;
  width: 25em;

  @media (min-width: $screen-md) {
    display: initial;
  }

  @media (min-width: $screen-lg) {
    font-size: 1rem;
  }

  @media (min-width: $screen-xl) {
    font-size: 1.5rem;
    width: auto;
  }
}

.footer__sponsor--move-down {
  margin-top: 1em;
}

.footer__spacer {
  display: none;

  @media (min-width: $screen-lg) {
    display: initial;
    width: 20em;
  }
}

.footer-hours {
  display: none;

  @media (min-width: $screen-lg) {
    display: flex;
    flex-direction: column;
  }
}

.footer-hours__hours {
  display: flex;
  padding-left: 3.8em;
}

.footer-hours__store {
  align-items: center;
  display: flex;
  margin-bottom: 1em;
  padding-right: 1.8em;
}

.footer-hours__icon {
  @extend .shape-circle;

  align-items: center;
  background-color: theme-color('button-secondary');
  border: 2px solid theme-color('button-secondary');
  color: theme-color('white');
  display: flex;
  justify-content: center;
  margin-right: 1.5em;
}

.footer-hours__col {
  display: flex;
  flex-direction: column;
}

.footer-hours__col--margin {
  margin-right: 2em;
}

.footer-hours__label {
  color: theme-color('white');
  font-size: 1rem;
  font-weight: 600;

  @media (min-width: $screen-xl) {
    font-size: 1.2rem;
  }
}

.footer-hours__days {
  align-items: center;
  display: flex;
}

.footer-hours__day {
  color: theme-color('white');
  font-size: 0.8rem;
  font-weight: 600;
  padding-right: 1.5em;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  width: 2em;

  @media (min-width: $screen-xl) {
    font-size: 1rem;
  }
}

.footer-hours__time {
  color: theme-color('white');
  font-size: 0.8rem;
  text-transform: uppercase;

  @media (min-width: $screen-xl) {
    font-size: 1rem;
  }
}
