@import '../../Styles/theme';
@import '../../Styles/text';
@import '../../Styles/forms';
@import '../../Styles/buttons';
@import '../../Styles/shapes';
@import '../../Styles/screens';
@import '../../Styles/scrollbar';

.optout__header {
  grid-area: header;
}
.optout__content {
  grid-area: content;
}

.main__text {
  font-size: 2em;
  line-height: 1.23;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: white;
  width: 100%;

  @media (min-width: $screen-lg) {
    font-size: 3em;
    line-height: 0.53;
    letter-spacing: 1.25px;
  }
}
.sub__text {
  font-size: 1em;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: white;

  @media (min-width: $screen-lg) {
    font-size: 2em;
  }
}
.sub_sub__text {
  font-family: Arial;
  font-size: 1em;
  color: white;
  padding: 2em;
  z-index: 3;

  width: 8em;
  position: relative;
  left: 1em;
  top: 2em;

  @media (min-width: $screen-lg) {
    font-size: 18px;
    width: 200px;
    padding-left: 0px;
    padding-top: 0px;
  }
}
.optout__footer {
  z-index: 2;
  display: flex;
  justify-content: center;
  font-size: 0.8em;
}
.terms__privacy {
  margin: 9px;
  color: white;
}
.terms__privacy a,
.terms__privacy > span {
  color: white;
}
.powered__by {
  color: white;
  margin: 10px;
  @media (min-width: $screen-lg) {
    padding-left: 62px;
  }
}
.images {
  z-index: 1;
  bottom: 0;
  margin-top: 0px;
}
.man__sitting__img {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  max-height: 40vh;
  @media (min-width: $screen-lg) {
    display: block;
  }
}
.woman__waving {
  position: absolute;
  bottom: 0;
  right: 0;
  max-height: 37vh;

  @media (min-width: $screen-xs) {
    display: block;
    max-height: 40vh;
  }
  @media (min-width: $screen-md) {
    max-height: 50vh;
  }
}
.center__container {
  display: flex;
  justify-content: center;
}
.center__container.sub_text {
  display: block;

  @media (min-width: $screen-lg) {
    display: flex;
    justify-content: center;
    padding-top: 35px;
  }
}
.location__message {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: solid 1px #ffffff;
  background-color: white;
  text-align: center;
  padding: 2px;
  margin: 10px;
}
.from-the-friendly-st {
  font-size: 17px;
  padding-bottom: 0.5em;
  text-align: center;
  color: #000000;
}
.text-style-1 {
  font-weight: bold;
  font-size: 24px;
  @media (min-width: $screen-lg) {
    font-size: 1.8em;
  }
}
.text-style-2 {
  font-weight: bold;
  font-size: 24px;
  @media (min-width: $screen-lg) {
    font-size: 2em;
  }
}

.optout__submit {
  @extend .button;
  @extend .button--primary;
  display: block;
  margin: 1em auto;
  padding: 1em 2em;
}
