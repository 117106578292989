@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/scrollbar';

.loc-group {
  display: grid;
  grid-template-rows: 5em auto 4em;
  grid-template-areas:
    'groupheader'
    'groupcontent'
    'groupfooter';
  height: calc(var(--vh, 1vh) * 100);
}

.loc-group__header {
  grid-area: groupheader;
}

.loc-group__content {
  background-color: theme-color('white');
  grid-area: groupcontent;
}

.loc-group__logo-parent {
  display: flex;
  justify-content: center;
}

.loc-group__logo-cont {
  width: 35%;

  @media (min-width: $screen-lg) {
    width: 18%;
  }
}

.loc-group__logo {
  width: 100%;
}

.loc-group__statement {
  font-size: 1rem;
  font-weight: 600;
  margin: 1em 0;
  padding: 0 3em;
  text-align: center;
}

.loc-group__iframe-body {
  padding: 0 0 0 0;

  @media (min-width: $screen-md) {
    padding: 0 0 0 0;
  }

  @media (min-width: $screen-lg) {
    padding: 0 2em 1em 2em;
  }
}

.loc-group__iframe {
  border: none;
  bottom: 0;
  box-shadow: 0 0 8px #999;
  height: 80vh;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.loc-group__footer {
  grid-area: groupfooter;
}

.loc-group__body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loc-group__statement {
  flex: 0 0 auto;
}

.loc-group__iframe-body {
  flex: 1 0 auto;
}
