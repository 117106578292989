@import '../../Styles/theme';
@import '../../Styles/text';
@import '../../Styles/buttons';
@import '../../Styles/screens';

.page.page--patientsurvey {
  @media (min-width: $screen-lg) {
    height: auto;
    grid-template-rows: 5em auto 2em;
  }
}

.patient-survey__storeinfo {
  text-align: center;
  h2 {
    margin: 0;
  }
  p {
    margin: 0;
  }
}

.patient-survey__header {
  align-items: center;
  border-bottom: 1px solid theme-color('white');
  display: flex;
  justify-content: center;
  grid-area: header;

  @media (min-width: $screen-lg) {
    justify-content: initial;
    padding-left: 3em;
  }
}

.patient-survey__brand {
  width: 14em;

  @media (min-width: $screen-lg) {
    width: 18em;
  }
}

.patient-survey__logo {
  width: 100%;
}

.patient-survey__content {
  grid-area: content;
  position: relative;
  background-color: theme-color('white');
  width: calc(100% - 2rem);
  margin: 0 auto;
  padding: 1rem;
  @media (min-width: $screen-lg) {
    padding: 1rem 6rem;
    max-width: 1024px;
    width: auto;
  }
}

.patient-survey__title {
  @extend .screen-reader-only;
}

.patient-survey__hero {
  @media (min-width: $screen-lg) {
    background-image: url('../../Assets/Images/Hero-Image.jpg');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    margin-top: 1px;
    opacity: 0.8;
    position: absolute;
    width: 100%;
  }
}

.patient-survey__overlay {
  @media (min-width: $screen-lg) {
    background-color: #00acea; // Custom overlay color needed only for desktop.
    height: 100%;
    opacity: 0.5;
    position: absolute;
    width: 100%;
  }
}

.patient-survey__shape {
  height: 100%;

  @media (min-width: $screen-lg) {
    background-image: url('../../Assets/Images/White-Semi-Circle.png');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    display: initial;
    height: 100.15%;
    overflow-y: auto;
    position: absolute;
    width: 65%;
    z-index: 1;
    @media (max-height: 925px) {
      width: 58%;
    }
  }

  @media (min-width: $screen-xxl) {
    width: 55%;
  }
}

.patient-survey__location {
  color: theme-color('white');
  font-size: 2rem;
  margin: 0.5em 0 0;
  text-align: center;

  @media (min-width: $screen-lg) {
    color: theme-color('black');
    margin: 2em 0 0 2.5em;
    text-align: initial;
  }
}

.patient-survey__address {
  color: theme-color('white');
  font-weight: 600;
  margin: 0;
  text-align: center;

  @media (min-width: $screen-lg) {
    color: theme-color('black');
    font-size: 1.2rem;
    margin: 0 0 0 4.2em;
    text-align: initial;
  }
}

.patient-survey__maps {
  color: theme-color('white');
  display: block;
  font-weight: 600;
  margin-bottom: 0;
}

.patient-survey__body {
  background-color: theme-color('white');
  height: calc(100% - 120px);
  margin-top: 1.5em;
  padding: 1.5em 1.5em 0 1.5em;

  @media (min-width: $screen-lg) {
    height: auto;
    margin: 2em 0 0 5em;
    padding: 0;
    width: 60%;
  }

  @media (min-width: $screen-lg) {
    width: 75%;
  }
}

.patient-survey__statement1 {
  color: theme-color('primary');
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  width: 12em;

  @media (min-width: $screen-lg) {
    font-size: 1.6rem;
    width: auto;
  }

  @media (min-width: $screen-xxl) {
    font-size: 2rem;
    width: auto;
  }
}

.patient-survey__statement2 {
  color: theme-color('secondary');
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 0.5em;

  @media (min-width: $screen-xxl) {
    margin-bottom: 2.5em;
  }
}

.patient-survey__footer {
  align-items: center;
  background-color: theme-color('white');
  border-top: 2px solid theme-color('background');
  display: flex;
  grid-area: footer;
  justify-content: center;

  @media (min-width: $screen-lg) {
    align-items: flex-end;
    background: none;
    padding: 0 0 1em 2.5em;
  }
}

.patient-survey__sponsor {
  color: theme-color('black');

  @media (min-width: $screen-lg) {
    color: theme-color('white');
    font-size: 0.8rem;
  }
}

.patient-survey__ {
  max-width: 24em;
}

.patient-survey____header {
  align-items: flex-start;
  background-color: theme-color('white');
  display: flex;
  justify-content: space-between;
}

.patient-survey____spacer {
  width: 3em;
}

.patient-survey____title {
  color: theme-color('white');
  margin: 0;
  text-transform: uppercase;
}

.patient-survey____close {
  background: none;
  border: none;
  color: theme-color('black');
  cursor: pointer;
  display: flex;
  padding-right: 1em;
  padding-top: 1em;
}

.patient-survey____content {
  background-color: theme-color('white');
  padding: 1em 1.5em 2em;
}

.patient-survey____statement {
  color: theme-color('primary');
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2em;
  margin: 0;

  @media (min-width: $screen-lg) {
    text-align: center;
  }
}

.patient-survey____comments {
  border: 2px solid theme-color('background', 'medium');
  box-sizing: border-box;
  display: block;
  height: 10em;
  margin: 2em 0;
  padding: 0.5em;
  resize: none;
  width: 100%;
}

.patient-survey____footer {
  background-color: theme-color('white');
  display: flex;
  justify-content: center;

  @media (min-width: $screen-lg) {
    justify-content: flex-end;
  }
}

.patient-survey____add-review {
  @extend .button;

  background-color: theme-color('button-secondary');
  border-color: theme-color('button-secondary');
  color: theme-color('white');
  width: 100%;

  @media (min-width: $screen-lg) {
    width: 30%;
  }
}

.patient-survey____share {
  background-color: theme-color('rating');
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1em;
}

.patient-survey____add-google {
  @extend .button;
  @extend .button--secondary;

  color: theme-color('white');
  width: 100%;

  @media (min-width: $screen-lg) {
    width: 30%;
  }
}

.patient-survey____thankyou {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 2rem 0 0;
  text-align: center;
}

.patient-survey____gesture {
  color: theme-color('primary');
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0 2em;
  text-align: center;
}

.patient-survey____dismiss {
  @extend .button;

  background-color: theme-color('white');
  border: 2px solid theme-color('background', 'medium');
  color: theme-color('black');
  margin-top: 1em;
  width: 100%;
}
