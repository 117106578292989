/* Typography imported from Google Fonts */
@import '../../Styles/theme';
@import '../../Styles/buttons';
@import '../../Styles/shapes';
@import '../../Styles/screens';
@import '../../Styles/loading';

.container {
  justify-items: center;
  text-align: center;
  position: relative;
  display: grid;
}

/* Styles for the hero image */
.container-hero {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black; /* fallback color */
  background-position: center;
  background-size: cover;
  min-height: 281px;

  @media (min-width: $screen-sm) {
    min-height: 450px;
  }

  @media (min-width: $screen-md) {
    min-height: 575px;
  }

  @media (min-width: $screen-lg) {
    min-height: 704px;
  }

  @media (min-width: $screen-xl) {
    min-height: 1114px;
  }

  @media (min-width: $screen-xxl) {
    min-height: 1386px;
  }
}

.container-logo {
  position: absolute;
  top: 6%;
  right: 8%;
  display: grid;

  @media (min-width: $screen-lg) {
    width: 40%;
  }

  @media (min-width: $screen-xl) {
    width: 35%;
  }
}

.container-logo > img {
  width: 50%;
  justify-self: right;

  @media (min-width: $screen-sm) {
    width: 60%;
  }

  @media (min-width: $screen-sm) {
    width: 80%;
  }
}

.container-logo > p {
  margin: 0;
}

.container-text {
  top: 25%;
  width: 30%;
  height: 70%;
  right: 8%;
  position: absolute;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: grid;
  grid-gap: 0.5rem;

  @media (min-width: $screen-sm) {
    right: 6%;
  }

  @media (min-width: $screen-md) {
    right: 7%;
  }

  @media (min-width: $screen-xl) {
    right: 5%;
  }
}

.container-text__3Rows {
  top: 25%;
  height: 70%;
}

.container-text__2Rows {
  top: 35%;
  height: 50%;
}

.container-text > p {
  margin: 0;
  font-size: 0.75rem;

  @media (min-width: $screen-sm) {
    font-size: 1rem;
  }

  @media (min-width: $screen-md) {
    font-size: 1.25rem;
  }

  @media (min-width: $screen-lg) {
    font-size: 1.5rem;
  }

  @media (min-width: $screen-xl) {
    font-size: 1.75rem;
  }

  @media (min-width: $screen-xxl) {
    font-size: 2rem;
  }
}

.container-text1 {
  color: white;
  font-size: 0.5rem;
  font-style: italic;
  text-align: right;

  @media (min-width: $screen-md) {
    font-size: 0.75rem;
  }

  @media (min-width: $screen-xl) {
    font-size: 1rem;
  }
}

.container-text2 {
  color: #f06b64;
  line-height: 1.25em;
  text-align: left;
}

.container-text3 {
  color: white;
  line-height: 1.25em;
  text-align: left;
}

.container-text4 {
  color: white;
  line-height: 1.25em;
  text-align: left;
}

.container-text4 > a {
  font-size: 2vw;
}

.header-container__content {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 1rem;

  @media (max-width: $screen-sm) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
    grid-row-gap: 1rem;
  }
}

.header-container__content--1Btn {
  @media (max-width: $screen-sm) {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 0.5rem;
  }
}

.header-logo--2Btn {
  @media (max-width: $screen-sm) {
    grid-column-start: 1;
    grid-column-end: 4;
    display: flex;
    justify-content: center;
  }
}

.header-logo--1Btn {
  @media (max-width: $screen-sm) {
    grid-column-start: 1;
    grid-column-end: 2;
    display: flex;
    justify-content: center;
  }
}

.header-content {
  background-color: #13395e;
  padding: 0.75rem 1rem;

  @media (min-width: $screen-sm) {
    padding: 0.75rem 2rem;
  }

  @media (min-width: $screen-lg) {
    padding: 0.75rem 7rem;
  }
}

.header-content > ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

.header-content > ul > li > img {
  width: 85%;

  @media (min-width: $screen-xl) {
    width: 65%;
  }
}

.header-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header-button-text {
  font-size: 0.75rem;
  padding: 5px 0px;
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  color: white;

  @media (min-width: $screen-sm) {
    padding: 5px 10px;
  }

  @media (min-width: $screen-lg) {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

.btn {
  background-color: #1c60a6;
  box-shadow: -10px 5px 5px rgba(0, 0, 0, 0.5);
  border-radius: 2em;
  height: 100%;
  width: 90%;

  @media (min-width: $screen-sm) {
    height: 90%;
  }

  @media (min-width: $screen-xxl) {
    height: 60%;
  }
}

/* content2 styles */
.footer-content {
  background-image: none;
  text-align: center;
  padding: 1rem;
}

.footer-content > ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
  list-style-type: none;
  padding-inline-start: 0px;
  grid-gap: 1.5rem;

  @media (min-width: $screen-sm) {
    grid-gap: 3rem;
  }

  @media (min-width: $screen-lg) {
    grid-gap: 5rem;
  }
}

.footer-content > ul > li {
  display: unset;
  flex-direction: row;
  align-items: center;

  @media (min-width: $screen-lg) {
    display: flex;
  }
}

.footer-content > ul > li > img {
  width: 40%;
  padding: 0rem 0.5rem;

  @media (min-width: $screen-sm) {
    width: 35%;
  }

  @media (min-width: $screen-lg) {
    display: flex;
    align-items: left;
    width: 20%;
    padding: 1rem 1.5rem;
  }
}

.footer-content > ul > li > p {
  display: flex;
  line-height: 1.5;
  color: white;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-size: 0.5rem;

  @media (min-width: $screen-sm) {
    width: 100%;
    text-align: center;
    justify-content: center;
    font-size: 0.75rem;
  }

  @media (min-width: $screen-lg) {
    width: 65%;
    text-align: left;
    justify-content: left;
    font-size: 1rem;
  }
}

/* footer */
footer {
  background-color: #13395e;
  padding: 0.75rem 1rem;
  color: white;
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  border-top: 5px solid #f06b64;

  @media (min-width: $screen-sm) {
    border-top: 10px solid #f06b64;
  }
}
