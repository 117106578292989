@import '../../Styles/theme';
@import '../../Styles/text';
@import '../../Styles/shapes';
@import '../../Styles/buttons';
@import '../../Styles/screens';
@import '../../Styles/scrollbar';

.details__header {
  grid-area: header;
}

.details__content {
  background-color: theme-color('white');
  grid-area: content;

  @media (min-width: $screen-lg) {
    margin: 2px 160px 0;
  }
}

.details__body {
  @extend .custom-scrollbar;

  @media (min-width: $screen-lg) and (max-height: 1080px) {
    height: 48vh;
  }
}

.details__title {
  @extend .screen-reader-only;
}

.details__subtitle {
  background-color: #050520;
  border-top: 1px solid #050520;
  color: theme-color('white');
  font-size: 1.2rem;
  font-weight: 600;
  margin: -1px 0 0;
  padding: 5px 0;
  text-align: center;
  width: 100%;
}

.details__statement1 {
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
  padding: 1.5em 1em 0;
  text-align: center;

  @media (min-width: $screen-lg) {
    font-size: 1.5rem;
    border-bottom: none;
    padding: 1.5em 0 0;
  }
}

.details__sub-statement1 {
  font-size: 1rem;
  margin: 0;
  padding: 1.5em;
  text-align: center;

  @media (min-width: $screen-lg) {
    font-size: 1.2rem;
    border-bottom: none;
    padding: 0 0 1.5em;
  }
}

.details__location {
  padding: 0 1em;

  @media (min-width: $screen-lg) {
    margin: 0 auto;
    width: 30em;
  }
}

.details__label {
  font-size: 1.2rem;
}

.details__label--nowrap {
  white-space: nowrap;
  font-size: 1.2rem;
}

.details__value {
  font-size: 1.2rem;
  padding-left: 1em;
}

.details__confirm {
  @extend .button;
  @extend .button--primary;

  display: block;
  margin: 0 auto;
  padding: 0.8em;
  text-transform: uppercase;
  width: 90%;

  @media (min-width: $screen-lg) {
    width: 22em;
  }
}

.details__statement2 {
  margin: 1em 0 1em;
  padding: 0 2em;
  text-align: center;
}

.details__error {
  color: theme-color('red');
  display: none;
  font-size: 0.8rem;
  margin-bottom: 1em;
  text-align: center;
}

.details__error--visible {
  display: block;
}

.details-modal {
  position: relative;
}

.details-modal__content {
  @extend .shape-circle;
  display: none;

  @media (min-width: $screen-lg) {
    align-items: center;
    background-color: theme-color('primary2');
    border: none;
    display: flex;
    flex-direction: column;
    height: 680px;
    justify-content: center;
    width: 680px;
  }
}

.details-modal__waitlist {
  color: theme-color('white');
  font-size: 1.5rem;
  font-weight: 600;
}

.details-modal__queue {
  color: theme-color('white');
  font-size: 1.2rem;
  font-weight: 600;
}

.details-modal__booked {
  color: theme-color('white');
  font-size: 1.5rem;
  font-weight: 600;
}

.details-modal__icon-cont {
  margin: 2em auto;
  width: 25%;
}

.details-modal__icon {
  width: 100%;
}

@keyframes icon-animate {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(-10deg);
  }
}

.details-modal__icon--animate {
  animation-name: icon-animate;
  animation-delay: 0.5s;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-duration: 0.3s;
}

.details-modal__download-ics {
  background: none;
  border: none;
  border-bottom: 2px solid theme-color('white');
  color: theme-color('white');
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0;

  &:focus {
    outline: none;
  }

  &:disabled {
    background: none;
    color: theme-color('background', 'light');
    border-bottom: 2px solid theme-color('background', 'light');
    cursor: not-allowed;
  }
}

.details-modal__directions {
  @extend .button;
  background: none;
  border: 1px solid theme-color('white');
  color: theme-color('white');
  display: inline-block;
  font-size: 0.8rem;
  margin: 2em 0;
  text-decoration: none;

  &:focus {
    border: 1px solid theme-color('white');
  }

  > .MuiSvgIcon-root {
    font-size: 1rem;
    padding-right: 0.2em;
    vertical-align: top;
  }
}

.details-modal__confirm {
  color: theme-color('white');
  font-size: 1rem;
  margin: 0;
  text-align: center;
  width: 20em;
}

.details-modal__info-icon {
  @extend .shape-circle;
  background: none;
  background-color: theme-color('gray');
  border: none;
  color: theme-color('white');
  cursor: pointer;
  font-size: 1.2rem;
  height: 30px;
  outline: none;
  position: relative;
  left: 0.2em;
  width: 30px;
}

.details-modal__tooltip {
  pointer-events: 'auto';
}

.details__opt-in-cont {
  display: flex;
  justify-content: flex-start;
  padding: 0 1.5em;

  @media (min-width: $screen-lg) {
    justify-content: center;
  }
}

.details__opt-in {
  margin: 0;
  color: #1f1f1f;
}

.details__privacy {
  margin: 0;
  padding: 0 1em;
  text-align: left;

  > a {
    color: theme-color('primary');
    text-decoration: none;
  }

  @media (min-width: $screen-lg) {
    text-align: center;
  }
}

.grating > a {
  font-size: 1em;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  text-decoration: none;
}
