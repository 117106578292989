.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
}

.overlay--flex {
  align-items: center;
  display: flex;
  justify-content: center;
}
