@import '../../Styles/theme';
@import '../../Styles/screens';

.loc-group-footer {
  align-items: center;
  background-color: theme-color('primary2');
  display: flex;
  height: 100%;
  justify-content: center;
}

.loc-group-footer__sponsor {
  color: theme-color('white');

  @media (min-width: $screen-lg) {
    font-size: 0.8rem;
  }
}
