@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/text';

.header {
  align-items: center;
  border-bottom: 1px solid theme-color('background', 'medium');
  display: flex;
  height: 99%;
  justify-content: space-between;
  padding: 0 1em;

  @media (min-width: $screen-lg) {
    padding: 0 3em;
  }
}

.header__back {
  background: none;
  border: 2px solid transparent;
  color: theme-color('white');
  cursor: pointer;
  padding: 0;
  outline: none;

  &:focus {
    border-color: theme-color('red');
  }

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.header__back--hidden {
  visibility: hidden;
}

.header__brand {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.header__logo-cont {
  width: 14em;

  img {
    width: 100%;
  }

  @media (min-width: $screen-lg) {
    margin-right: 3em;
    width: 18em;
  }
}

.header__contact {
  align-items: center;
  display: flex;

  > a {
    padding-right: 0.8em;
  }
}

.header__phone-cont {
  align-items: center;
  display: flex;
}

.header__whatsapp-link {
  padding: 0 1em;
  width: 35%;

  @media (min-width: $screen-lg) {
    width: 30%;
  }
}

.header__whatsapp-link--mobile {
  @media (min-width: $screen-lg) {
    display: none;
  }
}

.header__whatsapp-link--desktop {
  display: none;

  @media (min-width: $screen-lg) {
    display: initial;
  }
}

.header__whatsapp-logo {
  width: 100%;
}

.header__phone,
.header__close {
  color: theme-color('white');
  display: inline-block;

  > svg {
    font-size: 1.8rem;

    @media (min-width: $screen-lg) {
      font-size: 2rem;
    }
  }
}

.header__close {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.header__hide {
  visibility: hidden;
}
