@import '../../Styles/theme';
@import '../../Styles/text';
@import '../../Styles/shapes';
@import '../../Styles/buttons';
@import '../../Styles/screens';
@import '../../Styles/scrollbar';

.survey-reponse__header {
  grid-area: header;
}

.survey-reponse__content {
  background-color: theme-color('white');
  grid-area: content;

  @media (min-width: $screen-lg) {
    margin: 2px 160px 0;
  }
}

.survey-reponse__body {
  height: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.survey-reponse__subtitle {
  background-color: #050520;
  border-top: 1px solid #050520;
  color: theme-color('white');
  font-size: 1.2rem;
  font-weight: 600;
  margin: -1px 0 0;
  padding: 0.8em 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.survey-reponse .survey-reponse__spacer {
  width: 3em;
}

.survey-reponse__footer {
  background-color: theme-color('white');
  display: flex;
  justify-content: center;
}

.survey-reponse__thankyou {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
  text-align: center;
}

.survey-reponse__gesture {
  color: theme-color('primary');
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0 2em;
  text-align: center;
}

.survey-reponse__dismiss {
  @extend .button;

  background-color: theme-color('white');
  border: 2px solid theme-color('background', 'medium');
  color: theme-color('black');
  margin-top: 3em;
  width: 70%;

  @media (min-width: $screen-md) {
    width: 30%;
  }
}
