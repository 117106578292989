$theme-colors: (
  'primary': (
    'base': #00acea,
  ),
  'primary2': (
    'base': #303859,
  ),
  'secondary': (
    'base': #084575,
  ),
  'primary--disabled': (
    'base': #477ef1,
    'light': #477ef1,
    'dark': #477ef1,
  ),
  'background': (
    'base': #e0e0e0,
    'medium': #979797,
    'dark': #424242,
  ),
  'button-secondary': (
    'base': #fa716a,
  ),
  'black': (
    'base': #000,
    'light': #000,
    'dark': #000,
  ),
  'white': (
    'base': #fff,
    'light': #fff,
    'dark': #fff,
  ),
  'red': (
    'base': red,
    'light': #fa716a,
  ),
  'gray': (
    'base': #636363,
  ),
  'rating': (
    'base': rgba(0, 172, 234, 0.2),
  ),
);

@function theme-color($key: 'primary', $variant: 'base') {
  $map: map-get($theme-colors, $key);
  @return map-get($map, $variant);
}
