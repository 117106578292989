@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/buttons';

.rating {
  border: 2px solid theme-color('background', 'medium');
  margin: 1em 0;
  padding: 1em;

  @media (min-width: $screen-lg) {
    width: 75%;
    min-width: 25em;
  }
}

.rating__text {
  font-weight: 700;
  margin: 0;
  padding: 0 1em;
  text-align: center;
}

.rating__ratings {
  display: flex;
  list-style-type: none;
  margin: 1em 0;
  padding: 0;
  width: 100%;
}

.rating__ratings--align-left {
  justify-content: flex-start;
}

.rating__ratings--align-center {
  justify-content: space-between;
}

.rating__ratings--align-right {
  justify-content: flex-end;
}

.rating__item {
  &:hover {
    > .rating__icon-cont {
      background-color: theme-color('rating');
    }

    ~ .rating__item {
      > .rating__icon-cont {
        background-color: theme-color('rating');
      }
    }
  }
}

.rating__item--selected {
  > .rating__icon-cont {
    background-color: theme-color('rating');
  }

  ~ .rating__item {
    > .rating__icon-cont {
      background-color: theme-color('rating');
    }
  }
}

.rating__item--disabled {
  pointer-events: none;
}

.rating__icon-cont {
  align-items: center;
  border: 1px solid theme-color('background', 'medium');
  border-radius: 8px;
  display: flex;
  padding: 0.8em 0.5em;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media (min-width: $screen-lg) {
    border-width: 2px;
    padding: 0.6em 1.5em;
  }
}

.rating__icon {
  color: theme-color('black');
  pointer-events: none;
}

.rating__scale {
  display: flex;
  justify-content: space-between;
}

.rating__low-rating {
  font-weight: 700;
}

.rating__high-rating {
  font-weight: 700;
}

.rating__submit {
  @extend .button;
  @extend .button--primary;

  margin-top: 1em;
  width: 100%;
}
