@import '../../Styles/theme';
@import '../../Styles/buttons';
@import '../../Styles/screens';
@import '../../Styles/text';
@import '../../Styles/scrollbar';

.booking__header {
  grid-area: header;
}

.booking__content {
  background-color: theme-color('white');
  grid-area: content;

  @media (min-width: $screen-lg) {
    margin: 2px 160px 0;
  }
}

.booking__title {
  @extend .screen-reader-only;
}

.booking__subtitle {
  background-color: #050520;
  border-top: 1px solid #050520;
  color: theme-color('white');
  font-size: 1.2rem;
  font-weight: 600;
  margin: -1px 0 0;
  padding: 5px 0;
  text-align: center;
  width: 100%;
}

.booking__body-hasresource {
  @extend .custom-scrollbar;

  @media (min-width: $screen-lg) and (max-height: 1080px) {
    height: 52vh;
  }
}

.booking__body-noresource {
  @extend .custom-scrollbar;

  @media (min-width: $screen-lg) and (max-height: 1080px) {
    height: 70vh;
  }
}

.booking__resource-info {
  font-size: 1.2rem;
  margin: 0.5em 0 0;
  text-align: center;

  @media (min-width: $screen-lg) {
    margin: 1em 0 0;
  }
}

.booking__resource-value {
  border-bottom: 1px solid #979797;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  padding-bottom: 1em;
  text-align: center;

  @media (min-width: $screen-lg) {
    margin: 0 3em;
  }
}

.booking__appt-day {
  font-size: 1.2rem;
  text-align: center;
  margin: 2em 0 0.5em 0;
}

.booking__calendar-container {
  display: flex;
  justify-content: center;
  padding: 0 1em;
}

.booking__prev {
  padding: 9px 17px 28px;
  border: 1px solid #d2d2d2;
  background-color: #e4e4e4;
  cursor: pointer;
  height: 2px;
  border-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.booking__next {
  padding: 9px 17px 28px;
  border: 1px solid #d2d2d2;
  background-color: #e4e4e4;
  cursor: pointer;
  height: 2px;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.booking-calendar__input {
  padding: 3.5px 20px !important;
  margin: 0 !important;
  border-top: 1px solid #d6d6d6 !important;
  border-bottom: 1px solid #d6d6d6 !important;
}

.booking-calendar__input div input {
  text-align: center;
}

.booking__times-avail {
  text-align: center;
}

.booking__slots {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 1em;
  // height: 30em;
  // overflow-y: scroll;
  // &::-webkit-scrollbar {
  //   width: 0 !important;
  // }
  // overflow: -moz-scrollbars-none;
  // scrollbar-width: none;
  // -ms-overflow-style: none;

  @media (min-width: $screen-lg) {
    margin: 0 auto;
    width: 43em;
  }
}

.booking__button {
  @extend .button;
  @extend .button--primary;

  height: 40px;
  margin: 0 1em 1em;
  text-transform: uppercase;
  width: 140px;

  &:last-child {
    margin-bottom: 0;
  }
}

.booking__footer {
  grid-area: footer;
}

.booking-servicetype {
  display: flex;
  justify-content: center;
  align-items: center;
}

.booking-servicetype__options {
  width: 90%;

  @media (min-width: $screen-md) {
    width: 50%;
  }

  @media (min-width: $screen-lg) {
    width: 25%;
  }
}

.booking__reason {
  font-size: 1.2rem;
  margin: 2em 0 0.5em 0;
  text-align: center;
}

.grating > a {
  font-size: 1em;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  text-decoration: none;
}
