@import '../../Styles/theme';
@import '../../Styles/text';
@import '../../Styles/buttons';
@import '../../Styles/screens';
@import '../../Styles/shapes';
@import '../../Styles/loading';
@import '../../Styles/scrollbar';

.loc-info__header {
  grid-area: header;
}

.loc-info__content {
  grid-area: content;
  position: relative;
  display: flex;
  flex-direction: column;
}

.loc-info__hero {
  @media (min-width: $screen-lg) {
    background-image: url('../../Assets/Images/Hero-Image.jpg');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    margin-top: 1px;
    opacity: 0.8;
    position: absolute;
    width: 100%;
  }
}

.loc-info__overlay {
  @media (min-width: $screen-lg) {
    background-color: #00acea; // Custom overlay color needed only for desktop.
    height: 100%;
    opacity: 0.5;
    position: absolute;
    width: 100%;
  }
}

.loc-info-actions {
  display: none;
  flex-direction: column;

  @media (min-width: $screen-lg) {
    display: flex;
    padding-left: 2em;
  }

  @media (min-width: $screen-xl) {
    padding-left: 4em;
  }
}

.loc-info-actions__action-cont {
  display: flex;
  padding-top: 1em;
}

.loc-info-actions__button-subtext {
  margin: 0;
  font-size: 1em;

  @media (min-width: $screen-lg) {
    width: 20em;
    display: flex;
    justify-content: center;
  }
}

.loc-info-actions__action {
  @extend .button;
  @extend .button--primary;

  border-color: theme-color('red', 'light');
  background-color: theme-color('red', 'light');
  display: initial;
  font-size: 0.8rem;
  margin: 1.5em 0 0.5em;
  padding: 0.8em;
  text-transform: uppercase;
  width: 25em;

  &:focus {
    border-color: theme-color('red', 'light');
  }
}

.loc-info-actions__action--small {
  font-size: 0.8rem;
  height: 130px;
  width: 130px;
  transition: font-size 0.4s ease-out, height 0.4s ease-out, width 0.4s ease-out;
}

.loc-info-actions__action--confirm-queue {
  background-color: theme-color('button-secondary');
}

.loc-info-actions__word--hidden {
  visibility: hidden;
}

.loc-info-actions__queue-pos {
  color: theme-color('black');
  visibility: hidden;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1em 0 0.5em;
  opacity: 0;

  > span {
    color: theme-color('red', 'light');
  }
}

.loc-info-actions__queue-pos--visible {
  visibility: initial;
  opacity: 100;
  transition: opacity 0.4s ease-in;
}

.loc-info__shape {
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-lg) {
    background-image: url('../../Assets/Images/White-Semi-Circle.png');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    display: initial;
    height: 100.15%;
    position: absolute;
    width: 60%;
    z-index: 1;
  }
}

.loc-info__title {
  @extend .screen-reader-only;
}

.loc-info__location {
  color: theme-color('white');
  font-weight: 600;
  opacity: 100;
  text-align: center;
  transition: opacity 0.4s ease-in;
  width: 100vw;

  @media (min-width: $screen-lg) {
    color: theme-color('black');
    margin: 2em 0 0 2em;
    text-align: initial;
    width: unset;
  }

  @media (min-width: $screen-xl) {
    margin: 2em 0 0 4em;
  }
}

.loc-info__location--hide {
  opacity: 0;
  transition: opacity 0.4s ease-out;
  display: none;
}

.loc-info__address {
  font-size: 2rem;
  margin: 0.5em 0 0;

  @media (min-width: $screen-lg) {
    font-size: 2.5rem;
    margin: 0;
  }
}

.loc-info__maps {
  color: theme-color('white');
  font-weight: 600;
  margin-bottom: 0;
  display: block;

  @media (min-width: $screen-lg) {
    color: theme-color('black');
    font-size: 1.25rem;
  }
}

.loc-info-address__phone {
  color: theme-color('white');
  display: block;
  font-weight: 600;
  margin-top: 1em;

  @media (min-width: $screen-lg) {
    color: theme-color('black');
  }
}

.loc-info-address__phone--mobile-header {
  display: inline-flex;
  color: theme-color('white');
  font-weight: 600;
  margin-top: 1em;

  @media (min-width: $screen-lg) {
    color: theme-color('black');
  }
}

.loc-info-address__phone--mobile {
  color: theme-color('white');
  display: block;
  font-weight: 600;
  margin-top: 1em;
  margin-top: 0;
  margin-left: 5px;

  @media (min-width: $screen-lg) {
    color: theme-color('black');
  }
}

.loc-info-drawer--hours {
  height: 220px;
  transition: ease-in 0.2s;
}

.loc-info__drawer {
  align-items: center;
  background-image: linear-gradient(
    to top,
    theme-color('background'),
    theme-color('white') 30%
  );
  border-radius: 2em 2em 0 0;
  color: theme-color('white');
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  padding: 1.5em 1.5em 0 1.5em;
  position: relative;
  transition: margin-top 0.4s ease-out;
  z-index: 9999;
  flex: 1;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.loc-info__location--mobile {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  padding: 0 1.5em;
  position: relative;
  transition: margin-top 0.4s ease-out;
  z-index: 9999;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.loc-info__drawer--show-hours {
  transition: margin-top 0.4s ease-in;
}

.loc-info-drawer__guardrails {
  color: #fa7169;
  font-size: 0.9rem;
  margin: 0;
  text-align: center;
}

.loc-info-drawer__guardrails--desktop {
  text-align: initial;
  width: 35em;
  padding-top: 1em;
}

.loc-info__book-btn {
  @extend .button;
  @extend .button--primary;
  display: block;
  font-size: 0.8rem;
  margin: 4em auto 2em;
  padding: 0.8em;
  text-transform: uppercase;
  width: 22em;
}

.loc-info__book-btn {
  margin-bottom: 0;
}

.loc-info__book-btn--hide {
  display: none;
}

.loc-info__statement {
  color: theme-color('black');
  font-size: 1.1rem;
  margin: 2em 0 2em;
  text-align: center;
}

.loc-info-drawer__statement--hidden {
  opacity: 0;
  transition: 0.2s;
}

.loc-info-drawer__hours-cont {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 55%;
}

.loc-info-drawer__toggle-hours-cont {
  border: 1px solid theme-color('red', 'light');
  display: flex;
  width: 10em;
}

.loc-info__toggle-hours {
  align-items: center;
  background: none;
  border: 2px solid theme-color('red', 'light');
  border-radius: 0 35px 35px 0;
  bottom: 1em;
  color: theme-color('white');
  cursor: pointer;
  display: block;
  height: 65px;
  margin: 2em 0 2em 2.5em;
  position: relative;
  width: 14em;

  &:focus {
    outline: none;
  }
}

.loc-info__clock-cont {
  @extend .shape-circle;
  align-items: center;
  background-color: theme-color('red', 'light');
  border: none;
  display: flex;
  justify-content: center;
  height: 65px;
  margin-left: -45px;
  position: absolute;
  top: -0.16em;
  width: 65px;
}

.loc-info__toggle-hours-text {
  color: theme-color('black');
  font-weight: 800;
  text-transform: uppercase;
  padding-left: 1.5em;
  display: flex;
  justify-content: center;
  width: 85%;
}

.loc-info-drawer__store-hours--hidden {
  opacity: 0;
  transition: 0.2s;
}

.loc-info-drawer__sponsor {
  bottom: 1.5em;
  color: theme-color('black');
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.loc-info-drawer__hours-opt {
  align-items: center;
  color: theme-color('black');
  display: flex;
  font-weight: 700;
  justify-content: center;
  opacity: 0;
  text-transform: uppercase;
}

.loc-info-drawer__hours-opt--visible {
  opacity: 100;
  transition: 0.2s;

  > i {
    padding-right: 0.2em;
  }
}

.loc-info__day-times {
  color: theme-color('black');
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  visibility: hidden;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.loc-info__day-times--visible {
  opacity: 100;
  visibility: visible;
  transition: opacity 0.4s ease-in;
}

.loc-info-drawer__day {
  font-size: 0.7rem;
  font-weight: 700;
  margin: 0 1em;
  text-align: left;
  text-transform: uppercase;
}

.loc-info-drawer__time {
  font-size: 0.7rem;
  font-weight: 600;
  margin: 0;
  text-align: left;
}

.loc-info__footer {
  grid-area: none;
  display: none;

  @media (min-width: $screen-lg) {
    grid-area: footer;
    display: block;
  }
}

.google-rating > a {
  font-size: 1em;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  margin: 1em 0 1em 0;
  text-decoration: none;

  @media (min-width: $screen-lg) {
    margin: 2em 0 0 0;
    color: black;
    display: block;
  }
}
