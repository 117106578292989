@import '../../Styles/theme';
@import '../../Styles/text';
@import '../../Styles/buttons';
@import '../../Styles/screens';
@import '../../Styles/shapes';
@import '../../Styles/loading';

.googlerating__container {
  display: flex;
  align-items: center;
}
.googlerating__container > svg {
  color: #00acea;
}
