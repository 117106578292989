@import '../../Styles/theme';
@import '../../Styles/buttons';
@import '../../Styles/screens';

.stellest-sms-landing {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;
  grid-template-rows: 8.96em auto 2.2em !important;

  .stellest-sms-landing__header {
    background-color: theme-color('primary');
    color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 45px 60px;
    .stellest-sms-landing__logo {
      margin-right: auto;
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
    p {
      align-self: center;
    }
    .stellest-sms-landing__ebn-logo {
      margin-left: 15px;
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .stellest-sms-landing__title {
    display: flex;
    justify-content: center;
    background-color: theme-color('white');
    width: 100%;
    .stellest-sms-landing__title-contents {
      align-items: center;
      color: theme-color('black');
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 60px 0;

      h2 {
        font-size: 45px;
        text-align: center;
        margin: auto;
        max-width: 545px;
      }
      p {
        font-size: 20px;
        text-align: center;
        margin: auto;
        margin-top: 35px;
        max-width: 545px;
      }
    }
  }
  .stellest-sms-landing__content {
    align-items: center;
    background-color: theme-color('background', 'base');
    color: theme-color('black');
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 50px;

    .stellest-sms-landing__content__message {
      display: block;
    }

    .stellest-sms-landing__content__data {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .stellest-sms-landing__content__details {
        display: flex;
        flex-direction: column;
      }

      .stellest-sms-landing__content__actions {
        display: flex;
        flex-direction: column;

        .stellest-sms-landing__content__actions__primary-button {
          @extend .button;
          @extend .button--primary;

          margin-top: 10%;
        }

        .stellest-sms-landing__content__actions__secondary-button {
          @extend .button;
          //@extend .button--secondary;

          margin-top: 30%;
        }
      }
    }
  }
  .stellest-sms-landing__footer {
    color: white;
    background-color: theme-color('primary');
    height: 100px;
    padding: 0;
    text-align: center;

    p {
      padding: 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1150px) {
  .stellest-sms-landing {
    .stellest-sms-landing__header {
      flex-direction: column;
      justify-content: center;
      padding: 0%;
      height: 100%;
      .stellest-sms-landing__logo {
        margin-bottom: 20px;
        margin-right: 0;
      }
      p {
        display: none;
      }
    }

    .stellest-sms-landing__content {
      padding: 0px 20px 50px;
      width: calc(100% - 40px);

      .stellest-sms-landing__content__data {
        flex-direction: column;

        .stellest-sms-landing__content__details {
          padding-left: 10%;
          padding-right: 10%;
        }

        .stellest-sms-landing__content__actions {
          flex-direction: row;
          padding-left: 10%;
          padding-right: 10%;
          justify-content: space-around;

          .stellest-sms-landing__content__actions__primary-button {
            margin-top: 0;
          }

          .stellest-sms-landing__content__actions__secondary-button {
            margin-top: 0;
          }
        }
      }
    }
  }
}
