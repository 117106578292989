@import '../../Styles/theme';
@import '../../Styles/text';
@import '../../Styles/buttons';
@import '../../Styles/shapes';
@import '../../Styles/scrollbar';

.confirm__header {
  grid-area: header;
}

.confirm__content {
  align-items: center;
  border-bottom: 1px solid theme-color('background', 'medium');
  display: flex;
  flex-direction: column;
  grid-area: content;
}

.confirm__title {
  @extend .screen-reader-only;
}

.confirm__subtitle {
  color: theme-color('white');
  font-size: 1.5rem;
  margin: 1em 0 0;
  padding: 0 2em;
  text-align: center;
}

.confirm__queue-num {
  color: theme-color('white');
}

.confirm__icon-cont {
  margin: 1em auto;
  width: 40%;
}

.confirm__icon {
  width: 100%;
}

@keyframes icon-animate {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(-10deg);
  }
}

.confirm__icon--animate {
  animation-name: icon-animate;
  animation-delay: 0.5s;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-duration: 0.3s;
}

.confirm__confirmation {
  color: theme-color('white');
  font-size: 1rem;
  text-align: center;
}

.confirm__download-ics {
  background: none;
  border: none;
  border-bottom: 2px solid theme-color('white');
  color: theme-color('white');
  cursor: pointer;
  font-size: 1.2rem;
  margin-top: 1em;
  padding: 0;

  &:focus {
    outline: none;
  }

  &:disabled {
    background: none;
    color: theme-color('background', 'light');
    border-bottom: 2px solid theme-color('background', 'light');
    cursor: not-allowed;
  }
}

.confirm__directions {
  @extend .button;
  background: none;
  border: 1px solid theme-color('white');
  color: theme-color('white');
  display: inline-block;
  margin: 2em 0 1em;
  text-decoration: none;

  &:focus {
    border: 1px solid theme-color('white');
  }

  > .MuiSvgIcon-root {
    padding-right: 0.2em;
    vertical-align: top;
  }
}

.confirm__info-icon {
  @extend .shape-circle;
  background: none;
  background-color: theme-color('gray');
  border: none;
  color: theme-color('white');
  cursor: pointer;
  font-size: 1.2rem;
  height: 30px;
  outline: none;
  position: relative;
  left: 0.2em;
  width: 30px;
}
