@import './Styles/screens';

.page {
  display: grid;
  grid-template-rows: 4em auto 3em;
  grid-template-areas:
    'header'
    'content'
    'footer';
  height: calc(var(--vh, 1vh) * 100);

  @media (min-width: $screen-lg) {
    grid-template-rows: 5em auto 2.2em;
    height: 100vh;
  }

  overflow-y: auto;
  scrollbar-color: #888;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  transform: translate3d(0, 0, 0);

  /* width */
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.page--home {
  display: grid;
  grid-template-rows: 4em auto;
  grid-template-areas:
    'header'
    'content';
  height: 100vh;

  @media (min-width: $screen-lg) {
    grid-template-rows: 5em auto 11em;
    grid-template-areas:
      'header'
      'content'
      'footer';
    height: 100vh;
  }
}

.page--feedback {
  @media (min-width: $screen-lg) {
    grid-template-rows: 5em auto 11em;
  }
}
