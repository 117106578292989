@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/text';

.loc-group-header {
  align-items: center;
  border-bottom: 1px solid theme-color('background', 'medium');
  display: flex;
  height: 99%;
  justify-content: center;
  padding: 0 1em;

  @media (min-width: $screen-lg) {
    justify-content: flex-start;
    padding: 0 3em;
  }
}

.loc-group-header__brand {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.loc-group-header__logo-cont {
  width: 14em;

  img {
    width: 100%;
  }

  @media (min-width: $screen-lg) {
    margin-right: 3em;
    width: 18em;
  }
}
