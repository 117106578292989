@import '../../Styles/theme';
@import '../../Styles/buttons';
@import '../../Styles/screens';
@import '../../Styles/text';
@import '../../Styles/scrollbar';

.resource__header {
  grid-area: header;
}

.resource__content {
  background-color: theme-color('white');
  grid-area: content;

  @media (min-width: $screen-lg) {
    margin: 2px 160px 0;
  }
}

.resource__title {
  @extend .screen-reader-only;
}

.resource__subtitle {
  background-color: #050520;
  border-top: 1px solid #050520;
  color: theme-color('white');
  font-size: 1.2rem;
  font-weight: 600;
  margin: -1px 0 0;
  padding: 5px 0;
  text-align: center;
  width: 100%;
}

.resource__preference {
  align-items: center;
  border-bottom: 1px solid theme-color('background', 'medium');
  display: flex;
  font-size: 1.2rem;
  font-weight: 700;
  justify-content: center;
  margin: 0;
  padding: 1.5em 0;

  @media (min-width: $screen-lg) {
    font-size: 1.5rem;
    border-bottom: none;
    padding: 1.5em 0 1em;
  }
}

.resource__types {
  @extend .custom-scrollbar;

  align-items: center;
  display: flex;
  flex-direction: column;
  height: 58vh;

  @media (min-width: $screen-lg) {
    align-items: initial;
    border-bottom: none;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    padding: 1em 3em 0;
  }

  @media (min-width: $screen-lg) and (max-height: 1080px) {
    height: 52vh;
  }
}

.resource__first-avail {
  border-bottom: 1px solid theme-color('background', 'medium');
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  padding: 1em 0;
  width: 100%;

  @media (min-width: $screen-lg) {
    border-bottom: none;
    margin: 0;
    padding: 0;
    width: auto;
  }
}

.resource__button {
  @extend .button;
  @extend .button--primary;

  font-size: 0.8rem;
  font-weight: 600;
  height: 3em;
  margin-bottom: 1em;
  padding: 0.8em;
  text-transform: uppercase;
  width: 22em;

  &:first-child {
    margin-bottom: 0;
  }

  @media (min-width: $screen-lg) {
    margin: 0 1em 1em;

    &:first-child {
      margin-bottom: 1em;
    }
  }
}

.resource__list {
  height: 40vh;
  overflow-x: auto;
  margin-bottom: 2em;
}

.resource__footer {
  grid-area: footer;
}

.grating > a {
  font-size: 1em;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  text-decoration: none;
}
