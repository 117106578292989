@import '../../Styles/theme';
@import '../../Styles/buttons';
@import '../../Styles/screens';
@import '../../Styles/scrollbar';
.reschedule {
  background-color: theme-color('primary2', 'base');
  .reschedule__header {
    background-color: #0894d5;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 20px 60px;
    width: calc(100% - 120px);
    .reschedule__stellest-logo {
      margin-right: auto;
      max-width: 100%;
      object-fit: contain;
      height: 100px;
    }
    p {
      margin-left: 80px;
      padding-top: 24px;
    }
    .reschedule__ebn-logo {
      margin-left: 15px;
      max-width: 100%;
      object-fit: contain;
    }
  }
  @media screen and (max-width: $screen-sm) {
    .reschedule__header {
      flex-direction: column;
      justify-content: center;
      padding: 20px 25px;
      width: calc(100% - 50px);
      .reschedule__stellest-logo {
        margin-bottom: 20px;
        margin-right: 0;
        object-fit: contain;
      }
      p {
        display: none;
      }
      .reschedule__ebn-logo {
        margin-left: 0;
        object-fit: contain;
      }
    }
  }

  .reschedule__title {
    display: flex;
    justify-content: center;

    .reschedule__title-contents,
    .reschedule__title-booking-contents {
      align-items: center;
      color: white;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-width: 700px;
      padding-top: 60px;
      width: 100%;
      .reschedule__icon-container {
      }
      h2 {
        font-size: 35px;
        text-align: center;
        margin: auto;
        max-width: 545px;
        width: 100%;
      }
      p {
        font-size: 20px;
        text-align: center;
        margin: auto;
        margin-top: 35px;
        max-width: 545px;
        width: 100%;
      }
    }

    .reschedule__title-booking-contents {
      border-bottom: 1px solid #979797;
      padding-bottom: 30px;

      h1 {
        font-size: 25px;
        text-align: center;
        margin: auto;
        width: 100%;
      }
    }
  }

  .reschedule__title-suggested-slots {
    padding-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }

  .alt-button {
    @extend .button;
    background: theme-color('primary2', 'base');
    border-color: theme-color('background', 'medium');
    color: white;
  }

  .reschedule__button {
    @extend .button;
    height: 40px;
    margin: 0 1em 1em;
    text-transform: uppercase;
    width: 140px;
    background: theme-color('primary', 'base');
    color: white;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .reschedule__booking {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    max-width: 700px;
    padding-bottom: 50px;
    width: 100%;

    hr {
      margin: 60px 0;
      width: 360px;
    }
    .reschedule__add-calendar {
      font-size: 28px;
      font-weight: bold;
    }
    .reschedule__get-directions {
      border: 1px solid white;
      border-radius: 5px;
      display: flex;
      margin-top: 50px;
      margin-bottom: 30px;
      padding: 10px;
      text-decoration: none;
      svg {
        margin-right: 5px;
        margin-top: -2px;
      }
    }
  }

  .reschedule__booking__appt-day {
    font-size: 1.2rem;
    text-align: center;
  }

  .reschedule__content {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    max-width: 700px;
    padding-bottom: 50px;
    width: 100%;

    hr {
      margin: 60px 0;
      width: 360px;
    }
    .reschedule__add-calendar {
      font-size: 28px;
      font-weight: bold;
    }
    .reschedule__get-directions {
      border: 1px solid white;
      border-radius: 5px;
      display: flex;
      margin-top: 50px;
      margin-bottom: 30px;
      padding: 10px;
      text-decoration: none;
      svg {
        margin-right: 5px;
        margin-top: -2px;
      }
    }

    p {
      padding-left: 1em;
      padding-right: 1em;
      text-align: center;
    }
  }

  .reschedule__booking__location-info {
    padding-top: 60px;
    padding-left: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 1em;

    p {
      border-top: 1px solid #979797;
      padding-top: 10px;
      text-align: center;
    }
  }

  .reschedule__footer {
    border-top: 3px solid #979797;
    color: white;
    height: 100px;
    padding: 0;
    text-align: center;
    width: 100%;
    p {
      padding: 0;
      width: 100%;
    }
  }
  .reschedule__booking__header {
    grid-area: header;
  }

  .reschedule__booking__content {
    background-color: theme-color('white');
    grid-area: content;

    @media (min-width: $screen-lg) {
      margin: 2px 160px 0;
    }
  }

  .reschedule__booking__subtitle {
    background-color: #050520;
    border-top: 1px solid #050520;
    color: theme-color('white');
    font-size: 1.2rem;
    font-weight: 600;
    margin: -1px 0 0;
    padding: 0.8em 0;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }

  .reschedule__booking__body {
    @extend .custom-scrollbar;

    overflow-x: hidden;
    color: theme-color('white');
    @media (min-width: $screen-lg) and (max-height: 1080px) {
      height: 52vh;
    }
  }

  .reschedule__booking__resource-info {
    font-size: 1.8rem;
    font-weight: 700;
    margin: 0.5em 0 0;
    text-align: center;

    @media (min-width: $screen-lg) {
      margin: 1em 0 0;
    }
  }

  .reschedule__booking__resource-value {
    border-bottom: 1px solid #979797;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    padding-bottom: 1em;
    text-align: center;

    @media (min-width: $screen-lg) {
      margin: 0 3em;
    }
  }

  .reschedule__booking__appt-day {
    font-size: 1.2rem;
    text-align: center;
  }
  .reschedule__booking__calendar {
    background: theme-color('white');
  }

  .reschedule__booking__calendar-container {
    display: flex;
    justify-content: center;
    padding: 0 1em;
  }

  .reschedule__booking__prev {
    color: theme-color('black');
    padding: 9px 17px 28px;
    border: 1px solid #d2d2d2;
    background-color: #e4e4e4;
    cursor: pointer;
    height: 2px;
    border-radius: 5px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
  }

  .reschedule__booking__next {
    color: theme-color('black');
    padding: 9px 17px 28px;
    border: 1px solid #d2d2d2;
    background-color: #e4e4e4;
    cursor: pointer;
    height: 2px;
    border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }

  .reschedule__booking-calendar__input {
    padding: 3.5px 20px !important;
    margin: 0 !important;
    border-top: 1px solid #d6d6d6 !important;
    border-bottom: 1px solid #d6d6d6 !important;
  }

  .reschedule__booking-calendar__input div input {
    color: theme-color('black');
    text-align: center;
  }

  .reschedule__booking__times-avail {
    text-align: center;
  }

  .reschedule__booking__slots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: $screen-lg) {
      margin: 0 auto;
      width: 43em;
    }
  }

  .reschedule__booking__button {
    @extend .button--primary;

    height: 40px;
    margin: 0 1em 1em;
    text-transform: uppercase;
    width: 140px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .reschedule__booking__footer {
    grid-area: footer;
  }
}

@media screen and (max-width: 1150px) and (min-width: $screen-sm) {
  .reschedule {
    .reschedule__header {
      flex-direction: column;
      justify-content: center;
      .reschedule__stellest-logo {
        margin-bottom: 20px;
        margin-right: 0;
      }
      p {
        display: none;
      }
    }
    .reschedule__content {
      padding: 0px 20px 50px;
      width: calc(100% - 40px);
      p {
        text-align: center;
      }
    }
  }
}
