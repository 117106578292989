@import '../../Styles/theme';
@import '../../Styles/buttons';
@import '../../Styles/scrollbar';
@import '../../Styles/screens';

.patient-survey__survey-header {
  padding: 1em 0.5em;
  @media (min-width: $screen-lg) {
    padding: 1.5em 2em;
  }
}

.patient-survey__survey-header--dialog {
  border-bottom: 2px solid theme-color('background', 'medium');
}

.patient-survey__survey-header--questionaire {
  border-bottom: 1px solid theme-color('background', 'medium');
}

.patient-survey__survey-close {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 5px;
  top: 10px;
}

.patient-survey__survey-title {
  color: theme-color('primary');
  font-size: 1.5rem;
  margin: 0;
  padding: 0 0.6em;
}

.patient-survey__survey-title--dialog {
  color: theme-color('primary');
  font-size: 1.8rem;
  text-align: center;
}

.patient-survey__survey-statement {
  font-weight: 600;
  margin: 0;
  padding: 0 3em;
}

.patient-survey__survey-statement--dialog {
  color: theme-color('black');
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}

.patient-survey__survey-start {
  @extend .button;
  @extend .button--primary;

  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1.5em auto;
  text-transform: uppercase;
  width: 85%;
}

.patient-survey__survey-body {
  padding: 0 1em;
}

.patient-survey__survey-questions {
  padding: 0 2em 1.5em;
}

.patient-survey__survey-list {
  @extend .custom-scrollbar;

  height: calc(var(--vh, 1vh) * 62);
  padding: 0 1.1em 0 1.5em;
}

.patient-survey__survey-list--dialog {
  height: auto;
}

.patient-survey__survey-question {
  font-weight: 600;
  margin-bottom: 1em;
}

.patient-survey__survey-sublist {
  list-style-type: none;
  margin-top: 0.5em;
  padding: 0;
}

.patient-survey__form-label {
  margin-bottom: 0;
}

.patient-survey__form-multiinput {
  display: block;
  border-radius: 0;
  height: 8em;
  margin-top: 1em;
  resize: none;
  width: 100%;
}

.patient-survey__survey-footer {
  border-top: 1px solid theme-color('background', 'medium');
  padding: 1em;
}

.patient-survey__survey-submit {
  @extend .button;
  @extend .button--primary;

  width: 100%;
}

.patient-survey__survey-submit--dialog {
  display: block;
  margin: 0 auto;
  width: 90%;
  @media (min-width: $screen-lg) {
    margin: 0 1.5em;
  }
}

.patient-survey__survey-question-body {
  display: flex;
  flex-direction: column;
}

.patient-survey__form-multiinput--dialog {
  height: 12em;
  font-size: 1rem;
}

@media (min-width: $screen-lg) {
  .patient-survey__survey-body {
    display: flex;
    padding: 0 6rem;
  }

  .patient-survey__survey-column-cont {
    display: flex;
  }

  .patient-survey__survey-column {
    width: 50%;
  }

  .patient-survey__survey-column1 {
    width: 70%;
  }
}
