@import '../../Styles/screens';

.stellest-confirmation {
  background-color: #303859;
  .header {
    background-color: #0894d5;
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 20px 60px;
    width: calc(100% - 120px);
    .stellest-logo {
      margin-right: auto;
      max-width: 100%;
      object-fit: contain;
      height: 100px;
    }
    p {
      margin-left: 80px;
    }
    .ebn-logo {
      margin-left: 15px;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .foot-note-indicator {
    font-weight: normal;
    font-size: 15px;
    padding-left: 2px;
    vertical-align: top;
  }

  .foot-note {
    font-size: 11px;
  }

  @media screen and (max-width: $screen-sm) {
    .header {
      flex-direction: column;
      justify-content: center;
      padding: 20px 25px;
      width: calc(100% - 50px);
      .stellest-logo {
        margin-bottom: 20px;
        margin-right: 0;
        object-fit: contain;
      }
      p {
        display: none;
      }
      .ebn-logo {
        margin-left: 0;
        object-fit: contain;
      }
    }
  }

  .title {
    display: flex;
    justify-content: center;
    .title-contents {
      align-items: center;
      color: white;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-width: 700px;
      padding: 60px 0;
      width: 100%;
      .icon-container {
        display: flex;
        justify-content: center;
        width: 100%;
      }
      h2 {
        font-size: 35px;
        text-align: center;
        margin: auto;
        margin-top: 30px;
        max-width: 545px;
        width: 100%;
      }
      p {
        font-size: 20px;
        text-align: center;
        margin: auto;
        margin-top: 35px;
        max-width: 545px;
        width: 100%;
      }
      hr {
        margin-top: 60px;
        width: 360px;
      }
    }
  }
  .content {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    max-width: 700px;
    padding-bottom: 10px;
    width: 100%;
    a {
      color: white;
    }
    .add-calendar {
      font-size: 28px;
      font-weight: bold;
    }
    .get-directions {
      border: 1px solid white;
      border-radius: 5px;
      display: flex;
      margin-top: 50px;
      margin-bottom: 30px;
      padding: 10px;
      text-decoration: none;
      svg {
        margin-right: 5px;
        margin-top: -2px;
      }
    }
    p {
      text-align: center;
    }
  }
  .footer {
    border-top: 3px solid #979797;
    color: white;
    height: 100px;
    padding: 0;
    text-align: center;
    width: 100%;
    p {
      padding: 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1150px) {
  .stellest-confirmation {
    .header {
      flex-direction: column;
      justify-content: center;
      .stellest-logo {
        margin-bottom: 20px;
        margin-right: 0;
      }
      p {
        display: none;
      }
    }
    .content {
      padding: 0px 20px 10px;
      width: calc(100% - 40px);
      p {
        text-align: center;
      }
    }
  }
}
