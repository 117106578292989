html {
  height: -webkit-fill-available;
}

body {
  background-color: #13395e;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
  margin: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

body::before {
  content: 'mobile';
  display: none;
}

@media (min-width: 980px) {
  body::before {
    content: 'tablet';
  }
}

@media (min-width: 1200px) {
  body::before {
    content: 'desktop';
  }
}

.App {
  background-image: url('./Assets/Images/Background-Texture.jpg');
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 980px) {
  .App {
    background-image: url('./Assets/Images/Background-Texture-Desktop.png');
  }
}

@media (min-width: 980px) {
  .App {
    background-image: url('./Assets/Images/Background-Texture-Desktop.png');
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
